import FundUnicefKrTemplate from '@/page-blocks/about-us/clear/fund/unicef-kr/FundUnicefKrTemplate';
import ReportKorea2022 from '@/page-blocks/about-us/clear/fund/unicef-kr/report.korea_2022';
import { PageProps } from 'gatsby';
import React from 'react';

const FundUnicefKr2022: React.FC<PageProps> = (props) => (
  <FundUnicefKrTemplate year={2022} {...props}>
    <ReportKorea2022 />
  </FundUnicefKrTemplate>
);

export default FundUnicefKr2022;
